@import "../../sass/mixins";
@import "../../sass/variables";

.ResourcePageContainer{
  clip-path: polygon(0 0%, 100% 0%, 100% 100%, 0% 100%);
  padding-top: 8%;
  display: flex;
  justify-content: center;
  // background-color: pink;
  // width: 100vw;

  @include respond(tab-land){
    padding-top: 18%;
    width: 100vw;
    // background-color: green;
  }
  
  .ResourceSection{
    margin-bottom: 2rem;
    // background-color: blue;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 8rem;

    @include respond(tab-land){
      grid-template-columns: 1fr 1fr;
    }

    @include respond(phone){
      display: flex;
      flex-direction: column;
    }
  }
}