@import "../../sass/mixins";
@import "../../sass/variables";

.News{
  width: 25vw;
  height: 45rem;
  position: relative;
  // background-color: red;
  // margin-right: 3rem;
  border-radius: 1rem;
  color: $color-black;

  @include respond(tab-land){
    width: 40vw;
  }

  @include respond(phone){
    width: 90vw;
  }

  .OverlayHidden{
    position: absolute;
    width: 100%;
    height: 0%;
    opacity: 0;
    overflow-y: scroll;
    padding: 1rem ;
    background-color: $color-grey-light;
    transition: all .2s;
    z-index: 2;

    .Description{
      margin: 2rem 0;
    }
  }

  .OverlayVisible{
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 1;
    overflow-y: scroll;
    padding: 1rem ;
    background-color: $color-white;
    box-shadow: 0 0 1rem grey;
    border-radius: .5rem;
    transition: all .2s;
    z-index: 2;
    transform: translate(1rem, 1rem);

    .Link{
      // background-color: blue;
      margin-top: 1rem;
      // width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .Close{
        transition: all .2s;
        border-bottom: 1px solid $color-black;
        font-weight: bold;

        &:hover{
          cursor: pointer;
          border-bottom: 4px solid $color-black;
        }
      }

      a{
        padding: 1rem 2rem;
        background-color: $color-grey-light;
        transition: all .2s;
        // color: $color-primary;
        font-weight: bold;
        cursor: pointer;

        &:hover{
          background-color: darken($color: $color-grey-light, $amount: 10);
          transform: translateY(-2px);
          // box-shadow: 0 0 2rem darken($color: $color-grey-light, $amount: 20);
        }
      }
    }

    .Description{
      margin: 2rem 0;
    }
  }

  .Image{
    height: 60%;
    width: 100%;

    img{
      height: 100%;
      width: 100%;
      margin-bottom: 0;
    }
  }

  .Details{
    margin-top: 0;
    width: 100%;
    height: 40%;
    display: flex;
    flex-direction: column;
    // background-color: blue;
    justify-content: space-between;

    .NewsTitle{
      margin-top: 1.5rem;
    }

    .Footer{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .NewsTag{
        background-color: darken($color: $color-grey-light, $amount: 5);
        padding: 1rem 2rem;
        border-radius: 10rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .Link{
        padding: 1rem 2rem;
        background-color: $color-white;
        color: $color-black;
        // box-shadow: 0 0 1rem rgb(180, 180, 180);
        transition: all .2s;
        // color: $color-primary;
        font-weight: bold;
        cursor: pointer;

        &:hover{
          background-color: darken($color: $color-grey-light, $amount: 10);
          transform: translateY(-2px);
          // box-shadow: 0 0 2rem darken($color: $color-grey-light, $amount: 20);
        }
      }
    }
  }
}