.spaceBetweenHorizontal{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.sigInContainer, .forgotPasswordContainer, .sigUpContainer{
  width: 100%;
  min-height: 50vh;
  display: flex;
  justify-content: center;
  margin: 10rem 0;
  background-color: $color-white;

  .sigIn, .forgotPassword{
    width: 35%;

    @include respond(tab-land){
      width: 60%;
      padding-top: 5rem;
    }

    @include respond(phone){
      width: 90%;
      font-size: 1.7rem;
    }
  }

  .sigUp{
    width: 50%;

    @include respond(tab-land){
      width: 90%;
      padding-top: 4rem;
    }
  
    @include respond(phone){
    }
  }

}