@import "../../sass/mixins";
@import "../../sass/variables";

.ContentContainer{
  // background-color: red;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 9vh;

  @include respond(tab-land){
    // background-color: blue;
    padding-right: 0;
    width: 100%;
  }

  @include respond(phone){
    
  }

  .ContentBox{
    height: 70vh;
    width: 70vh;
    margin-top: -5%;
    display: flex;
    position: relative;
    box-shadow: 0 0 4rem $color-black;

    @include respond(tab-land){
      width: 100%;
      height: auto;
      margin-top: 0;
      box-shadow: 0 0 4rem transparent;
      // background-color: green;
      display: flex;
      flex-direction: column;

    }

    img{
      width: 100%;
      height: 100%;
      // border-radius: 10rem;
      object-fit: cover;

      @include respond(tab-land){
        height: 40vh;
        margin-top: 0;
      }
    }

    .TextBox{
      background-color: $color-grey-light;
      border: 1rem solid $color-white;
      width: 100%;
      bottom: -10%;
      right: 90%;
      position: absolute;
      padding: 0 1rem 1rem 1rem;

      @include respond(tab-land){
        right: 0;
        position: static;
        margin-top: 3%;
      }

      .LinkButton{
        padding: 1rem 2rem;
        float: right;
        background-color: $color-primary;
        color: $color-white;
        font-weight: bold;
        transition: .2s all;
        margin-top: 1rem;

        &:hover{
          // background-color: $color-primary-dark;
          box-shadow: 0 0 1rem $color-secondary;
          transform: translateY(-2px);
        }
      }

      .Title{
        transform: translateY(-100%);

        @include respond(tab-land){
          transform: translateY(0);
        }

        @include respond(tab-land){
          transform: translateY(0);
          font-size: 2.5rem;
          margin-bottom: 1rem;
        }
      }
    }
  }
}