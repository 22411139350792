@import "../../sass/mixins";
@import "../../sass/variables";

.lifeBasketProgramContainer{
  @include horizontal-gutter;
  padding: 0 3vw;
  background-color: $color-white;
  // background-color: red;
  // clip-path: polygon(0 15%, 100% 0%, 100% 85%, 0% 100%);
  // clip-path: polygon(0 0, 100% 0, 100% 80%, 0% 100%);

  // background-color: purple;
  // clip-path: polygon(0 20%, 100% 0, 100% 80%, 0% 100%);

  @include respond(tab-land){
    padding: 0 ;
  }

  @include respond(phone){
    padding: 0 ;
  }

  .lifeBasketProgram{
    // background-color: red;
  // clip-path: polygon(0 20%, 100% 0%, 100% 80%, 0% 100%);
    display: flex;
    align-items: center;
    width: 100%;
    padding: 26vh 0 23vh 0;
    margin-top: -10vh;
    // height: 100rem;

    @include respond(tab-land){
      flex-direction: column;
      padding: 26vh 0 10vh 0;
    }
    
    @include respond(phone){
      padding: 26vh 0 15vh 0;
    }

    .basket{
      width: 60%;
      // background-color: blue;

      img{
        box-shadow: 0 0 4rem $color-black;
      }

      @include respond(tab-land){
        flex-direction: column;
        width: auto;
      }

      @include respond(auto){
        flex-direction: column;
        width: auto;
      }

      .basket-info-image-large{
        width: 40vw;
        height: 40vw;
        padding: 1rem;
        @include borderRadius(1000rem);
        background-color: grey;
        position: relative;
        z-index: 10;
        box-shadow: 0 0 4rem $color-black;

        @include respond(tab-land){
          width: 45vw;
          height: 40vw;
          @include borderRadius(1rem);
        }
  
        @include respond(phone){
          width: 45vw;
          height: 40vw;
          @include borderRadius(1rem);
        }
  
        .basket-info-image-medium{
          width: 25vw;
          height: 25vw;
          padding: .5rem;
          border-top: 4px solid red;
          border-bottom: 4px solid red;
          @include borderRadius(1000rem);
          @include  translate(100%, -30%);
          position: relative;
          z-index: 2;

          @include respond(tab-land){
            width: 28vw;
            height: 28vw;
            @include borderRadius(1rem);
            border-top: 0px solid red;
            border-bottom: 0px solid red;
            @include  translate(-85%, 20%);
          }

          @include respond(phone){
            width: 30vw;
            height: 30vw;
            @include borderRadius(1rem);
            border-top: 0px solid red;
            border-bottom: 0px solid red;
            @include  translate(-90%, 20%);
          }
  
          img{
            width: 100%;
            height: 100%;
            @include borderRadius(1000rem);

            @include respond(tab-land){
              @include borderRadius(1rem);
            }

            @include respond(phone){
              @include borderRadius(1rem);
            }
          }
        }
  
        .basket-info-image-small{
          width: 20vw;
          height: 20vw;
          padding: .5rem;
          border-right: 4px solid red;
          border-left: 4px solid red;
          @include borderRadius(1000rem);
          @include translate(80%, 5%);
          position: relative;
          z-index: 3;

          @include respond(tab-land){
            width: 28vw;
            height: 28vw;
            @include borderRadius(1rem);
            border-right: 0px solid red;
            border-left: 0px solid red;
            @include  translate(140%, -80%);
          }

          @include respond(phone){
            width: 30vw;
            height: 30vw;
            @include borderRadius(1rem);
            border-top: 0px solid red;
            border-bottom: 0px solid red;
            @include  translate(130%, -80%);
          }
          
          img{
            width: 100%;
            height: 100%;
            @include borderRadius(1000rem);

            @include respond(tab-land){
              @include borderRadius(1rem);
            }

            @include respond(phone){
              @include borderRadius(1rem);
            }
          }
        }
      }
    }

    .description{
      width: 40%;
      // background-color: grey;
      // background-color: purple;
      // padding: 2rem;
      // @include borderRadius(1rem);
      // border : 3px solid $color-primary;

      .BasketTitle{

      }

      @include respond(tab-land){
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 5rem;
        width: 70%;
      }

      @include respond(phone){
        width: 100%;
        padding: 0 1rem;

        .BasketTitle{
          font-size: 2.6rem;
        }
      }

      .title{ 
      }

      .textContainer{
        // background-color: darken($color: $color-white, $amount: 4);
        // background-color: $color-secondary;
        // color: $color-white;
        // box-shadow: 0px 0px 4px 2px darken($color: $color-grey-light, $amount: 5);
        // padding: 4rem 2rem;
        margin: 3rem 0;
        @include borderRadius(1rem);
        .text{
          
        }
      }

      // .buttonUnderline{
      //   font-weight:  bold;
      //   font-size: 2.2rem;
      //   float: right;
      //   padding-bottom: 2px;
      //   position: relative;
      //   @include rotate(-3deg);
      
      //   @include underline;

      //   &:hover{
      //     @include rotate(0deg);
      //     color: $color-primary;
      //   }
      // }
    }
  }
}