@import "../../sass/mixins";
@import "../../sass/variables";

.HomePage_NewsContainer{
  background-color: $color-secondary;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  // padding: 5rem;
  clip-path: polygon(0 10%, 100% 0%, 100% 100%, 0% 100%);
  margin-top: -10rem;
  padding: 13rem 0;
  color: $color-white;

  @include respond(phone){
    clip-path: polygon(0 3%, 100% 0%, 100% 97%, 0% 100%);
    padding: 10rem 0;
    margin-top: -8rem;
  }

  .Title{
    text-align: center;
    margin-bottom: 4rem;

    @include respond(phone){
      font-size: 2.9rem;
    }
  }

  .NewsContainer{
    width: 85vw;
    // background-color: green;
    display: grid;
    grid-template-columns: 25vw 25vw 25vw;
    grid-column-gap: 5vw;
    grid-row-gap: 4vw;

    @include respond(tab-land){
      grid-template-columns: 31.67vw 31.67vw 31.67vw;
      grid-column-gap: 1.5vw;
      width: 98vw;
    }
    
    @include respond(phone){
      display: flex;
      flex-direction: column;
      padding: 0 1.5rem;
      width: 100vw;
    }
  }

  .HomeNews{
    background-color: $color-white;
    box-shadow: 0 0 4rem $color-black;

    @include respond(tab-land){
      width: 100%;
    }
    
    @include respond(phone){
      width: 100%;
      margin-bottom: 5rem;
    }
  }

  .HomeFooterLink{
    background-color: $color-primary;
    color: $color-white;
  }

  
}