@import "../../sass/mixins";
@import "../../sass/variables";

.moreInfoContainer{
  // @include layout-padded;
  background-color: transparent;
  // @include translate(0, -9vh);
  // background-color: red;
  width: 100%;
  display: flex;
  justify-content: center;
  // background-color: red;
  clip-path: polygon(0 10%, 100% 0%, 100% 90%, 0% 100%);
  margin-top: -10rem;
  padding: 15rem 0 15rem 0;
  background-color: $color-grey-light;
  background-color: $color-secondary;

  @include respond(tab-land){
    padding: 15rem 0 5rem 0;
    clip-path: polygon(0 5%, 100% 0%, 100% 95%, 0% 100%);
  }
  
  @include respond(phone){
    margin-top: -6rem;
    clip-path: polygon(0 2%, 100% 0%, 100% 98%, 0% 100%);
  }

  .MainTitle{
    text-align: center;
    margin-bottom: 3rem;
  }

  .moreInfo{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    // flex-wrap: wrap;
    padding: 5rem 0;
    width: 90%;
    // padding: 3rem 0;
    // height: 60rem;
    // color: $color-white;

    @include respond(big-desktop) {
      .first{
        @include translate(0, 9rem);
      }
  
      .last{
        @include translate(0, 2rem);
      }
  
      .middle{
        @include translate(0, -4.5rem);
      }
    }

    @include respond(tab-land){
      width: 98%;
      flex-direction: column;
      align-items: center;
      padding: 0;
    }
    
    @include respond(phone){
      width: 98%;
      flex-direction: column;
      align-items: center;
    }


    .infoItem{
      width: 30%;
      height: 40rem;
      // background-color: blue;
      padding: 0 1.5rem;
      @include borderRadius(1rem);
      @include transitionWithTime(.2s);
      // font-weight: bold;
      box-shadow: 0px 0px 4rem $color-black;
      background-color: $color-grey-light;
      // color: $color-white;

      @include respond(tab-land){
        width: 50%;
        margin-bottom: 10%;
      }

      @include respond(phone){
        width: 93%;
        margin-bottom: 25%;
        height: 50rem;
      }

      &:hover{
        box-shadow: 1px 0px 6px 3px lighten($color: $color-black, $amount: 90);
        .imgContainer{
          @include translate(0, -18%);
        }
      }

      .imgContainer{
        width: 100%;
        height: 60%;
        @include borderRadius(1rem);
        overflow: hidden;
        @include translate(0, -13%);

        // @include respond(tab-land){
        //   height: 0%;
        // }

        img{
          width: 100%;
          height: 100%;
          @include borderRadius(1rem);
        }
        
      }

      .moreInfoText{
        width: 100%;
        height: 40%;
        @include translate(0, -15%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        // background-color: pink;

        .title{
          font-size: 20px;
          font-weight: bold;
          display: inline-block;
          padding-bottom: 3px;
          position: relative;
          margin-bottom: 1rem;
          
        }
      }
    }
  }
}

// .more-info{
//   width: 100%;
//   height: 40rem;
//   display: flex;
//   justify-content: center;
//   //background-color: #E2E8F0;
//   // height: 100rem;
//   @include layout-padded;


//   .more-info-container{
//     display: flex;
//     flex-direction: row;
//     justify-content: space-between;

//     // background-color: red;

//     .first{
//       transform: translateY(-3rem)    ;
//       -webkit-transform: translateY(-3rem)    ;
//       -moz-transform: translateY(-3rem)    ;
//       -ms-transform: translateY(-3rem)    ;
//       -o-transform: translateY(-3rem)    ;
// }

//     .middle{
//       transform: translateY(-9rem)    ;
//       -webkit-transform: translateY(-9rem)    ;
//       -moz-transform: translateY(-9rem)    ;
//       -ms-transform: translateY(-9rem)    ;
//       -o-transform: translateY(-9rem)    ;
// }

//     .last{
//       transform: translateY(-6rem)    ;
//       -webkit-transform: translateY(-6rem)    ;
//       -moz-transform: translateY(-6rem)    ;
//       -ms-transform: translateY(-6rem)    ;
//       -o-transform: translateY(-6rem)    ;
// }

//     .info-item{
//       width: 30%;
//       background-color: white;
//       padding: 0 1.5rem;
//       //height: 30rem;
//       background-color: $color-grey-light;
//       @include borderRadius(1rem);
//       @include transitionWithTime(.2s);

//       &:hover{
//         cursor: pointer;
        
//         .img-container{
//           -moz-transform:translate(0,-2.5em);
//           -o-transform:translate(0,-2.5em);
//           -webkit-transform:translate(0,-2.5em);
//           // transform: translateY(-20%);
//           // -webkit-transform: translateY(-20%);
//           // -moz-transform: translateY(-20%);
//           // -ms-transform: translateY(-20%);
//           // -o-transform: translateY(-20%);
//         }
//       }

//       .img-container{
//         width: 100%;
//         height: 60%;
//         overflow: hidden;
//         border-radius: 6px;
//         -webkit-border-radius: 6px;
//         -moz-border-radius: 6px;
//         -ms-border-radius: 6px;
//         -o-border-radius: 6px;
//         transform: translateY(-10%);
//         -webkit-transform: translateY(-10%);
//         -moz-transform: translateY(-10%);
//         -ms-transform: translateY(-10%);
//         -o-transform: translateY(-10%);

//         img{
//           width: 100%;
//           height: 100%;
//         }
//       }

//       // .underline{
//       //   width: 80%;
//       //   background-color: red;
//       //   padding: .5rem ;
//       //   clip-path: polygon(0 0, 100% 0%, 95% 100%, 0% 100%);
//       //   transform: translateY(-30%);
//       //   -webkit-transform: translateY(-30%);
//       //   -moz-transform: translateY(-30%);
//       //   -ms-transform: translateY(-30%);
//       //   -o-transform: translateY(-30%);
//       // }

//       .info-item-title{
//         //text-transform: uppercase;
//         font-size: 20px;
//         font-weight: bold;
//         display: inline-block;
//         padding-bottom: 7px;
//         // background-color: red;
//         position: relative;
//         //color: red;
//         transform: translateY(-45%);
//         -webkit-transform: translateY(-45%);
//         -moz-transform: translateY(-45%);
//         -ms-transform: translateY(-45%);
//         -o-transform: translateY(-45%);
//         margin-bottom: .3rem;
        
//         &:before{
//           content: "";
//           position: absolute;
//           width: 50%;
//           height: 1px;
//           bottom: 0;
//           border-bottom: 3px solid red;
//         }
//       }

//       .info-item-text{
//         color: $color-black;
//       }
//     }
//   }
// }
