@import "../../sass/mixins";
@import "../../sass/variables";

.selectInput{
  padding: 0 2rem;
  color: darken($color: $color-grey-light, $amount: 65);
  width: 100%;
  border: 1px solid darken($color: $color-grey-light, $amount: 15);
  height: 4.5rem;
  font-family: inherit;
  font-size: inherit;
  @include borderRadius(20px);
  background-color: $color-grey-light;
  //background-color: red;

}