@import "../../sass/mixins";
@import "../../sass/variables";

.userPageContainer{
  //@include layout-padded;
  display: grid;
  grid-template-columns: 20% 79%;
  grid-column-gap: 1%;
  height: 85vh;
  padding: 1.5vh ;
  background-color: darken($color: $color-grey-light, $amount: 1);
  box-shadow: 0px 0px 4px 1px darken($color: $color-grey-light, $amount: 10);
}