@import "../../sass/mixins";
@import "../../sass/variables";

.sideBySide{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 3rem;
  //background-color: red;

  @include respond(tab-land){
  }

  @include respond(phone){
    display: flex;
    flex-direction: column;
  }
}

.sideBySideLongQuestions{
  display: grid;
  grid-template-columns: 70% 30%;
  //grid-column-gap: 3rem;
  //background-color: red;
  align-items: center;
  margin: 1rem 0;

  @include respond(phone){
    border-bottom: 1px solid darken($color: #fff, $amount: 4);
    font-size: 1.8rem;
    padding: 1rem 0;
  }
}

.authErrorMessage{
  color: $color-primary;
  //margin-left: 35%;
  //@include translate(-50%, 0);
}

.sigUp{
}

.selectOption{
  .options{
    display: flex;
    justify-content: space-between;
    margin: 6% 0;
    
    @include respond(phone){
      // background-color: green;
      height: auto;
      width: 100%;
      overflow-x: scroll;
      flex-direction: column;
    }

    .option{
      //background-color: $color-grey-light;
      box-shadow: 0px 2px 4px 2px darken($color: $color-grey-light, $amount: 5);
      position: relative;
      width: 40%;
      //padding: 1rem;
      z-index: 1;
      @include  borderRadius(1rem);
      @include transitionWithTime(.3s);
      height: 30rem;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      cursor: pointer;

      @include respond(tab-land){
      }
  
      @include respond(phone){
        width: 100%;
        margin-bottom: 3rem;
      }

      .option-text{
        opacity: 0;
        width: 92%;
        margin-bottom: 1rem;
        //text-align: center;
        @include transitionWithTime(1.5s);
      }

      .option-inner{
        //background-color: grey;
        //overflow: hidden;
        position: absolute;
        width: 92%;
        height: inherit;
        display: flex;
        align-items: flex-end;
        @include transitionWithTime(.6s);
        margin: 0 auto;
        left : 4%;
        bottom: 1.5rem;
        z-index: 2;
        @include  borderRadius(1rem);
        //background: linear-gradient(to bottom right , #5850C3 , #D72C6F)

        img{
          width: 100%;
          height: 100%;
        }

        .option-title{
          display: inline-block;
          padding-bottom: 5px;
          position: relative;
          margin: 2rem;
          color: white;
          padding: 1rem 1rem 0.3rem;
          background-color: rgba(0,0,0,.8);
          //background-color: red;
          @include underline;
          @include transitionWithTime(.6s);
          @include translate(-3.5rem, 0);
          z-index: 3;
        }
      }

      &:hover{
        box-shadow: 0px 6px 10px 6px darken($color: $color-grey-light, $amount: 10);

        .option-title{
          @include translate(0 , 0);
        }

        .option-inner{
          @include translate(0, -50%);
          height: 70%;
        }

        .option-text{
          opacity: 1;
        }
      }

    }
    .optionActive{
      box-shadow: 0px 2px 3px 2px darken($color: $color-primary, $amount: 0);

      .option-title{
        @include translate(0 , 0);
      }

      .option-inner{
        @include translate(0, -50%);
        height: 70%;
      }
    
      .option-text{
        opacity: 1;
      }
    }
  }
}

