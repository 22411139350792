@import "../../sass/mixins";
@import "../../sass/variables";

.VolunteeringPage{
  width: 100%;
  // background-color: red;
  
  .VolunteerIntro{
    width: 100%;
    height: 50vh;
    clip-path: polygon(0 0, 100% 0, 100% 95%, 50% 100%, 0 95%);
    // background-color: green;
    display: grid;
    grid-template-columns: 1fr 1fr;

    .Image{
      background-color: blue;
      width: 100%;
      // transform:  translateY(-20vh);
      box-shadow: 0 0 3rem $color-black;

      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .DetailText{
      // background-color: $color-grey-light;
      height: 50vh;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;


    }
  }

  .Reasons{
    background-color: $color-white;
    padding: 5vw;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10rem;

    .title{
      margin-bottom: 2rem;
    }
  }
}