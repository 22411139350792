@import "../../sass/mixins";
@import "../../sass/variables";

.header{
  height: 8%;
  width: 100%;
  // background-color: $color-primary;
  border-bottom: 1px solid $color-grey-light;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // background-color: red;

  i{
    font-size: 4rem;
    color: $color-primary;
  }

  @include horizontal-gutter;

  @include respond(tab-land){
  }

  @include respond(phone){
    padding: 0 2vw;

    i{
      transform: translateY(65%);
    }
  }

  .logoLinkBig{
    width: 35vw;
    height: 160%;
    z-index: 30;
    transform: translateY(38%);
    background-color: $color-white;
    // display: flex;
    // align-items: flex-end;
    // background-color: red;

    @include respond(tab-land){
      width: 50vw;
    }
  
    @include respond(phone){
      width: 90vw;
      transform: translate(2%,25%);
    }

    img{
      width: 100%;
      height: 95%;
    }
  }

  .logoLink{
    width: 5vw;
    height: 100%;
    z-index: 30;
    background-color: $color-white;
    // transform: translateY(25%);
    // background-color: red;
    display: flex;
    align-items: flex-end;

    img{
      width: 100%;
      height: 95%;
    }
  }

  &__links{
    // background-color: grey;
    display: flex;
    // justify-content: space-between;
    align-items: center;
    height: 100%;

    span{
      height: 100%;
    }
    // background-color: green;

    .basketDonateButton{
      display: flex;
      align-items: center;
      // background-color: $color-grey-light;
      color: $color-primary;
      height: 100%;
      padding: 0 1rem;
      transition: all .2s;

      &:hover{
        background-color: darken($color: $color-grey-light, $amount: 0);
      }

      i{
        margin-right: 1rem;
        font-size: 3rem;
      }
    }

    &--link{
      // margin-right: 2.5rem;

      transition: all .2s ease-in-out;
      color: inherit;
      // background-color: red;
      padding: 0 2rem;
      height: 100%;
      display: flex;
      // justify-content: space-between;
      align-items: center;

      &--active{
        font-weight: bold;
        color: $color-primary;
        cursor: pointer;
        background-color: $color-grey-light;
      }


      &:hover{
        font-weight: bold;
        // color: $color-primary;
        cursor: pointer;
        background-color: $color-grey-light;
      }
    }

    &--cta{
      display: flex;
      align-items: center;
      transition: all .2s ease-in-out;
      background-color: $color-primary;
      height: 100%;
      padding: 0 2rem;
      color: $color-white;

      .thumbnail{
        height: 5rem;
        width: 5rem;
        display: flex;
        justify-content: center;
        background-color: $color-white;
        color: $color-black;
        font-weight: bold;
        font-size: 2.2rem;
        align-items: center;
        border-radius: 1000rem;
        margin-right: 1rem;
        transition: all .2s ease-in-out;
      }

      img{
        height: 5rem;
        width: 5rem;
        border-radius: 1000rem;
        margin-right: 1rem;
        transition: all .2s ease-in-out;
      }

      &:hover{
        background-color: $color-primary-dark;
        cursor: pointer;
        font-weight: bold;
      }
    }
  }
}