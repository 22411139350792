@import "../../sass/mixins";
@import "../../sass/variables";

.sponsorsContainer{
  @include layout-padded;
  // background-color: $color-grey-light;
  // background-color: red;
  width: 100%;
  .sponsors{
    width: 100%;
    padding: 3rem 0 10rem 0;

    .sponsorImage{
      width: 90%;
      height: 20rem;
      display: flex;
      justify-content: center;
      align-items: center;
    //   margin-right: 1rem;
      // background-color: blue;
      

      img{
        height: 90%;
        width: 100%;
    //     //background-color: blue;
    //     //margin-right: 1rem;
      }
    }

    img{
      text-align: center;
      height: 15rem;
      width: 90%;
      //background-color: blue;
      //margin-right: 1rem;
    }
    .bigThanksText{
      text-align: center;
      color : $color-black;
      // font-family: 'Bubblegum Sans', cursive;
      // font-size: 3.5rem;
      // background-color: grey;
      margin-bottom: 5rem;
      // display: inline-block;
      // padding-bottom: 3px;
      // position: relative;
      // //margin-bottom: 1rem;
      // @include underline;

      @include respond(tab-land){
      }
      
      @include respond(phone){
        font-size: 2.7rem;
      }
    
      .bold{
        color : $color-primary;
        font-size: 5rem;
        margin: 0 1rem;
      }
    }
  }
}