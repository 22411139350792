@import "../../sass/mixins";
@import "../../sass/variables";

.MyTask{
  display:  grid;
  grid-template-columns: 40% 59%;
  height: 100%;
  grid-column-gap: 1%;
  background-color: darken($color: $color-grey-light, $amount: 1);

  .TaskList{
    background-color: $color-white;
    height: 100%;
    @include borderRadius(1rem);
    padding : 1rem;

    .selectDropdown{
      width: 50%;
    }

    .DisplayEvents{
      height: 85%;
      width: 100%;
      overflow-y: scroll;

      .ActiveEvent{
        background-color: darken($color: $color-grey-light, $amount: 5);
        border-radius: 2rem;
        box-shadow:  0 0 1rem grey;
      }

      .MyEvent{
        transition: all .2s;

        &:hover{
          cursor: pointer;
          transform: translateY(-2px);
        }
      }
    }
  }

  .TaskDetails{
    background-color: $color-white;
    height: 100%;
    @include borderRadius(1rem);
    // padding : 1rem;
    // background-color: red;
    
    .eventsContainer{
      width: 100%;
      height: 100%;

      .eventsDetails{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;

        .eventsDetailsInfo{
          overflow-y: scroll;
          // background-color: red;
          height: 74vh;

          .mainEventInfo{
            // background-color: blue;
            padding: 1rem;
            border-bottom: 2px solid $color-grey-light;
            margin-bottom: 1rem;

            .mainText{
              margin: 1rem 0;
            }

            .dates{
              display: flex;
              align-items: center;
              justify-content: space-between;

              .notice{
                font-weight: bold;
                // color: $color-primary;
              }
            }
          }
  
          .details{
            // height: 90%;
            padding : 1rem;
            overflow-y: scroll;
            // position: relative;
            // display: flex;
            // flex-direction: column;
            // background-color: green;

            .deleteButton{
              position: absolute;
              top: 1rem;
              right: 1rem;
              font-size: 2rem;
              padding: 1rem;
              border-radius: 1000rem;
              box-shadow: 0 0 1rem $color-secondary;
              background-color: $color-primary;
              color: $color-white;
              transition: all .2s;

              &:hover{
                box-shadow: 0 0 2rem $color-secondary;
                background-color: $color-primary-dark;
                color: $color-white;
                cursor: pointer;
              }
            }
  
            .message{
              // background-color: grey;
              display: table;
              min-width: 40%;
              margin-bottom: 1.5rem;
  
              .messageBox{
                // margin: 1rem 0;
                background-color: $color-grey-light;
                padding: .5rem 1rem;
                margin: .5rem 0;
                border-radius: 1rem;
              }
  
              .footer{
                display: flex;
                justify-content: flex-end;
                color: $color-grey-dark;
                font-size: 1.4rem;
              }
            }
          }
        }

        .inputField{
          // height: 10%;
        }
      }
    }
  }
}