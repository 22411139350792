//COLORS
$color-white: #fff;
$color-black: #000;

$color-primary: #FF0243;
$color-primary-light: #e35f87;
$color-primary-dark: #8f193d;
// #425E95


$color-grey-light: #F7F7F7;
$color-grey-dark: #777;


$color-secondary: #2D2D2D;

//FONTS
// $font-primary:  Acme, serif;
$font-primary:  'Bree Serif', serif;
// $font-primary:  Amaranth, serif;
// font-family: 'Amaranth', sans-serif;

//FONT-SIZES
$heading : 3rem;

$padding-sides : 10vw;

$shadow-dark: 0 0rem 6rem rgba(0,0,0,.6);
$shadow-light: 0 2rem 5rem rgba(0,0,0,.06);

// $line: 1px solid var($color-grey-light-2);




