@import "../../sass/mixins";
@import "../../sass/variables";

.programPageContainer{
  // background-color: red;
  // @include layout-padded;

  .programPage{
    // background-color: blue;

    @include layout-padded;
    padding-top: 5rem;
  padding-bottom: 5rem;
    width: 100%;

    .info{
      // background-color: grey;
      display: flex;

      .content{
        width: 49.7%;
        padding: 6rem 2rem;

        .title{
          margin-bottom: 1rem;
          font-weight: bold;
          font-size: 2rem;
          word-spacing: .2rem;
          // color: $color-primary;
        }

        .text{
          // text-indent: 50px;
        }
      }

      .image{
        width: 49.7%;
        height: 45rem;
        padding: 2rem 2rem;
        // background-color: red;
        display: flex;
        align-items: flex-end;

        img{
          width: 100%;
          height: 90%;
        }
      }

      .center{
        width: .3%;
        background-color: $color-grey-light;
        display: flex;
        justify-content: center;
        padding: 2rem 0;
        .point{
          font-size: 2rem;
          color: $color-primary;
        }
      }
    }
  }

  .linkContainer{
    width: 100%;
    // background-color: blue;
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;

    .link{
      width: 23%;
      @include borderRadius(1000rem);
      background-color: $color-primary;
      padding: 1rem 0;
      color: $color-white;
      text-align: center;
      font-weight: bold;
      font-size: 1.8rem;

      &:hover{
        transform: scale(1.02);
        background-color:darken($color: $color-primary, $amount: 10);
        box-shadow: 0 0 1rem darken($color: $color-grey-light, $amount: 40);;
      }
    }
  }

  .ctaContainer{
    // background-color: grey;
    @include layout-padded;
    display: flex;
    justify-content: center;

    .cta{
      width: 55%;
      display: flex;
      flex-direction: column;;
      align-items: center;

      .title{
        margin-bottom: 1rem;
        font-weight: bold;
        font-size: 2.3rem;
        word-spacing: .2rem;
        // color: $color-primary;
      }

      .text{
        text-align:   center;
      }
    }
  }
}