@import "../../sass/mixins";
@import "../../sass/variables";

.nothingToShow{
  // background-color: blue;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $color-grey-dark;

  i{
    margin-bottom: 1rem;
    font-size: 4rem;
  }
}